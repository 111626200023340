import React from "react"
import { Row, Col } from "react-flexbox-grid"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout"
import HelmetWrapper from "../../../components/HelmetWrapper"
import LeadershipHero from "../../../components/LeadershipHero"
import ContentBlock from "../../../components/ContentBlock"
import Card from "../../../components/Card"
import DaynoCard from "../../../components/Card/dayno-card"
import BioModal from "../../../components/BioModal"

import SourceEmitter from "../../../helper/emitter"

import aboutUsImg from "../../../assets/images/sm_circle_AboutUs2.png"
import headerHero from "../../../assets/images/harmony-leadership.jpg"
import { GrantPrograms } from "../../../components/GrantPrograms"

import "./index.scss"
import BioDayno from "../../../components/BioModal/dayno"

let modalSubscription1 = null
let modalSubscription2 = null

const pageTitle = "Senior Leadership | Harmony Biosciences"
const pageDescription ="Learn about our team of professionals with deep experience and a proven track record of success in developing and commercializing novel treatments for patients."
const pageKeywords = "harmony biosciences"

class ourLeadership extends React.Component {
  leaders = null
  bod = null
  pageContent = null
  constructor(props) {
    super(props)
    this.leaders = this.props.data.allContentfulLeaders.edges.map(nodes => {
      const altRaw = JSON.parse(nodes?.node?.alt?.raw)
      const alt = altRaw && altRaw?.content?.[0]?.["content"]?.[0]?.value
      const bioRaw = JSON.parse(nodes?.node?.bio?.raw)
      const bio = bioRaw && bioRaw?.content?.[0]?.["content"]?.[0]?.value
      const photo = `https:${nodes?.node?.photo?.gatsbyImageData?.images?.fallback?.src}`
      return {
        order: nodes?.node?.order,
        alt,
        bio,
        name: nodes?.node?.name,
        photo,
        title: nodes?.node?.title,
      }
    })
    this.bod = this.props.data.allContentfulBoardOfDirector.edges.map(nodes => {
      const altRaw = JSON.parse(nodes?.node?.alt?.raw)
      const alt = altRaw && altRaw?.content?.[0]?.["content"]?.[0]?.value
      const bioRaw = JSON.parse(nodes?.node?.bio?.raw)
      const bio = bioRaw && bioRaw?.content?.[0]?.["content"]?.[0]?.value
      const photo = `https:${nodes?.node?.photo?.gatsbyImageData?.images?.fallback?.src}`
      return {
        order: nodes?.node.order,
        slug: nodes?.node.slug,
        name: nodes?.node.name,
        alt,
        bio,
        photo,
        title: nodes?.node?.title,
      }
    })
    this.pageContent = this.props.data.contentfulGrantProgram
    this.state = {
      modalVisible: false,
      currentBio: this.leaders[0],
      daynoVisible: false,
    }
    this.makeDaynoVisible = this.makeDaynoVisible.bind(this)
  }

  makeDaynoVisible() {
    console.log("hi");
    this.setState({
      daynoVisible: true,
    })
  }
  componentWillMount() {
    // opens modal
    modalSubscription1 = SourceEmitter.addListener(
      `onBioModalOpenEvent`,
      data => {
        this.setState({
          modalVisible: true,
          currentBio: data.person,
        })
        const isGA = typeof window.ga !== "undefined"

        if (isGA) {
          window.ga(
            "send",
            "event",
            "Button",
            "click",
            `Read Bio - ${data.person.name}`
          )
        }
      }
    )
    // closes modal
    modalSubscription2 = SourceEmitter.addListener(
      `onBioModalCloseEvent`,
      data => {
        this.setState({
          modalVisible: false,
          daynoVisible: false,
        })
      }
    )
  }

  componentWillUnmount() {
    modalSubscription1 && modalSubscription1.remove()
    modalSubscription2 && modalSubscription2.remove()
  }

  render() {
    const { currentBio, modalVisible, daynoVisible } = this.state
    return (
      <Layout jobCode="US-HAR-2200062/Aug 2022">
        <div id="our-leadership">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
            slug="/about-us/senior-leadership"
          />
          <LeadershipHero bg={headerHero}>
            <h1>Senior Leadership</h1>
          </LeadershipHero>
          <ContentBlock color="green" className="leadership">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={aboutUsImg} className="" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10}>
                <p>
                  Our company is led by a passionate team of professionals with
                  deep experience in the pharmaceutical industry and a proven
                  track record of success in developing and commercializing
                  novel treatments for patients.
                </p>
              </Col>
            </Row>
          </ContentBlock>
          {/* this.pageContent && <GrantPrograms pageContent={this.pageContent} /> */}
          {/* <Hero bg={headerHero}>
            <h1>Our Leadership</h1>
          </Hero>
          <ContentBlock color="green" className="leadership">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={aboutUsImg} className="" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10}>
                <p>
                  Our company is led by a passionate team of professionals with
                  deep experience in the pharmaceutical industry and a proven
                  track record of success in developing and commercializing
                  novel treatments for patients.
                </p>
              </Col>
            </Row>
          </ContentBlock> */}

          <BioModal person={currentBio} visible={modalVisible} />
          <BioDayno visible={daynoVisible} />

          <Row>
            {this.leaders.map((person, index) => {
              return (
                <Col xs={12} md={4} key={`leader-col-${index}`}>
                  <Card person={person} />
                </Col>
              )
            })}
          </Row>

          <Row>
            <Col xs={12} md={12}>
              <div className="bio-container">
                <h2>Board of Directors</h2>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={7}>
              <div className="bio-container">
                <p className="bmember">
                  <a href="/about-us/senior-leadership/jeff-aronin">
                    <strong>Jeffrey S. Aronin</strong>
                  </a>
                  <br />
                  Harmony Founder and Non-Executive Chairman
                  <br />
                  Paragon Chairman and Chief Executive Officer
                </p>
                <p className="bmember">
                  <a href="/about-us/senior-leadership/peter-anastasiou">
                    <strong>Peter Anastasiou</strong>
                  </a>
                  <br />
                  Chief Executive Officer
                  <br />
                  Capsida Biotherapeutics
                </p>

                <p className="bmember">
                  <a href="/about-us/senior-leadership/antonio-gracias">
                    <strong>Antonio Gracias</strong>
                  </a>
                  <br />
                  Managing Partner/Chief Investment Officer
                  <br />
                  Valor Equity
                </p>

                <p className="bmember">
                  <a href="/about-us/senior-leadership/mark-graf">
                    <strong>Mark Graf</strong>
                  </a>
                  <br />
                  Private Investor, Former Chief Financial Officer
                  <br />
                  Discover Financial Services
                </p>


              </div>
            </Col>
            <Col xs={12} md={5}>
              <div className="bio-container">
                <p className="bmember">
                  <a href="javascript:void(0);" onClick={this.makeDaynoVisible}>
                    <strong>Jeffrey M. Dayno</strong>
                  </a>
                  <br />
                  President and Chief Executive Officer
                  <br />
                  Harmony Biosciences
                </p>

                <p className="bmember">
                  <a href="/about-us/senior-leadership/juan-sabater">
                    <strong>Juan Sabater</strong>
                  </a>
                  <br />
                  Partner
                  <br />
                  Valor Equity
                </p>

                <p className="bmember">
                  <a href="/about-us/senior-leadership/gary-sender">
                    <strong>Gary Sender</strong>
                  </a>
                  <br />
                  Former Chief Financial Officer, Nabriva&nbsp;plc
                  <br />
                  Independent Board Member & Consultant
                </p>
                <p className="bmember">
                  <a href="/about-us/senior-leadership/linda-szyper">
                    <strong>Linda Szyper</strong>
                  </a>
                  <br />
                  Former Chief Operating Officer
                  <br />
                  McCann Health
                </p>
                <p className="bmember">
                  <a href="/about-us/senior-leadership/andreas-wicki">
                    <strong>Andreas Wicki, PhD</strong>
                  </a>
                  <br />
                  Chief Executive Officer
                  <br />
                  HBM Healthcare Investments (Cayman) Ltd
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Layout>
    )
  }
}

export default ourLeadership

export const pageQuery = graphql`
  query {
    allContentfulLeaders(sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          name
          title
          photo {
            id
            gatsbyImageData
          }
          alt {
            raw
          }
          bio {
            raw
          }
          order
        }
      }
    }
    allContentfulBoardOfDirector(sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          name
          order
          title
          slug
          photo {
            gatsbyImageData
            file {
              url
            }
          }
          alt {
            raw
          }
          bio {
            raw
          }
        }
      }
    }
    contentfulGrantProgram(slug: { eq: "our-leadership" }, container: {}) {
      slug
      pageTitle
      titleColumns
      heroImage {
        id
        gatsbyImageData
      }
      container {
        backgroundColor
        containerId
        className
        title {
          title
        }
        body {
          ... on ContentfulParagraph {
            id
            name
            body {
              raw
            }
          }
          ... on ContentfulAccordion {
            title {
              title
            }
            body {
              raw
            }
          }
        }
        thumbnail {
          gatsbyImageData
        }
      }
      footer {
        paragraph {
          body {
            raw
          }
        }
      }
      buttons {
        backgroundColor
        link
        label
      }
    }
  }
`
